<ion-row class="content-row">
  <ion-col class="content">
    <ion-row class="title-row">
      <!-- <ion-col class="title-content" *ngIf="!success">
        <ion-label color="light" *ngIf="section==='burn' && !callService">Are you sure you want to use the coupon?</ion-label>
        <ion-label color="light" *ngIf="section==='assign' && !callService">Are you sure you want to purchase the coupon?</ion-label>
        <ion-label color="light" *ngIf="callService && !loading">Something went wrong. Please try again later!</ion-label>
      </ion-col>
      <ion-col class="title-content" *ngIf="success && !loading">
        <ion-label color="light" *ngIf="section==='burn'">Successful use of coupon!</ion-label>
        <ion-label color="light" *ngIf="section==='assign'">Successful coupon purchase!</ion-label>
      </ion-col> -->
      <ion-col class="title-content">
        <ion-label color="light">{{text}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="buttons-row">
      <ion-col class="buttons-content" *ngIf="!success && !loading && !callService">
        <ion-button color="medium" (click)="clickButton(false)">
          <ion-label>Cancel</ion-label>
        </ion-button>
        <ion-button color="light" (click)="clickButton(true)">
          <ion-label>ΟΚ</ion-label>
        </ion-button>
      </ion-col>
      <ion-col class="buttons-content" *ngIf="success && !loading && callService">
        <ion-button color="light" (click)="clickButtonSuccess()">
          <ion-label>ΟΚ</ion-label>
        </ion-button>
      </ion-col>
      <ion-col class="buttons-content" *ngIf="!success && !loading && callService">
        <ion-button color="light" (click)="clickButtonError()">
          <ion-label>ΟΚ</ion-label>
        </ion-button>
      </ion-col>
      <ion-col class="buttons-content" *ngIf="!success && loading">
        <ion-label><ion-spinner color="light" name="dots"></ion-spinner></ion-label>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

