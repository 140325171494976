import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RedemptionService {

  constructor(private http: HttpClient) { }

  assignCoupon(body: any, customerQCcode: string, memberQCcode: string)  {
    const url = `${environment.endpointQC}/persons/${customerQCcode}/loyalty-membership/${memberQCcode}/coupons/multi-actions`;
    return this.http.post(url, body);
  }
}
