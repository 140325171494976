import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.storageService.getToken()).pipe(
      switchMap((token: any) => {
        let requestClone;
        if (request.url.includes('qc-api')) {
          requestClone = request.clone({
            headers: request.headers
              .append('Content-Type', 'application/json')
              .append('x-jwt-token', token.value),
            withCredentials: true,
          });
        } else {
          requestClone = request.clone({
            headers: request.headers.append('Content-Type', 'application/json'),
            params: request.params.append('observe', 'response'),
          });
        }

        return next.handle(requestClone).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          })
        );
      })
    );
  }
}
