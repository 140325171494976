import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { MenuComponent } from "../view/menu/menu.component";
import { CouponConfirmModalComponent } from "./coupon-confirm-modal/coupon-confirm-modal.component";
import { ScannerComponent } from "./scanner/scanner.component";

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, ZXingScannerModule],
  declarations: [ScannerComponent, MenuComponent, CouponConfirmModalComponent],
  exports: [ScannerComponent, MenuComponent, CouponConfirmModalComponent],
  entryComponents: [
    ScannerComponent,
    MenuComponent,
    CouponConfirmModalComponent,
  ],
})
export class SharedModule {}
