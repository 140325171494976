<ion-content>
  <ion-row>
    <ion-col class="icon-container">
      <div class="icon">
        <ion-img src="assets/imgs/kafd_logo.jpg" class="logo"></ion-img>
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="token === false">
    <ion-col class="button-login-container">
      <ion-button
        [routerLink]="['/login']"
        expand="block"
        fill="clear"
        color="light"
      >
        <ion-icon name="log-in"></ion-icon>
        <ion-label>Log in</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="token === true">
    <ion-col class="buttons-container">
      <ion-button
        [routerLink]="['/core/home']"
        (click)="navigate()"
        expand="block"
        fill="clear"
        color="light"
        [ngClass]="
          router.url.startsWith('/core/home') ? 'active-button' : 'button'
        "
      >
        <ion-icon name="apps"></ion-icon>
        <ion-label>Homepage</ion-label>
      </ion-button>
      <ion-button
        [routerLink]="['/core/notification']"
        (click)="navigate()"
        expand="block"
        fill="clear"
        color="light"
        disabled
      >
        <ion-icon name="notifications"></ion-icon>
        <ion-label>Notifications</ion-label>
      </ion-button>
      <ion-button
        [routerLink]="['/core/mail']"
        (click)="navigate()"
        expand="block"
        fill="clear"
        color="light"
        disabled
      >
        <ion-icon name="mail"></ion-icon>
        <ion-label>Messages</ion-label>
      </ion-button>
      <ion-button
        [routerLink]="['/core/member']"
        (click)="navigate()"
        expand="block"
        fill="clear"
        color="light"
        [ngClass]="
          router.url.startsWith('/core/member') ? 'active-button' : 'button'
        "
      >
        <ion-icon name="people"></ion-icon>
        <ion-label>Members</ion-label>
      </ion-button>
      <ion-button
        *ngIf="environment?.config?.reports?.allow && store"
        (click)="navigate()"
        [routerLink]="['/core/reports']"
        expand="block"
        fill="clear"
        color="light"
        [ngClass]="
          router.url.startsWith('/core/reports') ? 'active-button' : 'button'
        "
      >
        <ion-icon name="document-text-outline"></ion-icon>
        <ion-label>Reports</ion-label>
      </ion-button>
      <ion-button
        [routerLink]="['/core/settings']"
        (click)="navigate()"
        expand="block"
        fill="clear"
        color="light"
        disabled="true"
      >
        <ion-icon name="settings"></ion-icon>
        <ion-label>Settings</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="token === true">
    <ion-col class="logout-container">
      <ion-button
        expand="block"
        fill="clear"
        color="light"
        (click)="onLogout()"
      >
        <ion-icon name="log-out"></ion-icon>
        <ion-label>Log out</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
