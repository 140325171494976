import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from "../login/login.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private authService: LoginService) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedOut().then((isLoggedOut: any) => {
      if (isLoggedOut) {
        this.router.navigateByUrl("/login");
      }

      return !isLoggedOut;
    });
  }
}
