import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Coupon } from "src/app/models/member-info.model";
import { StorageService } from "../../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class CouponsUpdatedService {
  validCouponsChanged = new Subject<Coupon>();
  validCouponsArray: any;
  allCoupons: any;
  allCouponSets: any;

  constructor(private storageService: StorageService) {
    this.showValidCoupons();
  }

  removeCoupon(position: any) {
    this.validCouponsArray.splice(position, 1);
    this.validCouponsChanged.next(this.validCouponsArray);
  }

  addCoupon(coupon: any) {
    this.storageService.getCouponSetsData().then((couponsets: any) => {
      this.allCouponSets = JSON.parse(couponsets.value);

      this.allCouponSets.filter((couponset: any) => {
        if (couponset.couponSetCode === coupon.couponSetCode) {
          coupon.title = couponset.couponSetTitle;
        }
      });
      this.validCouponsArray.push(coupon);
      this.validCouponsChanged.next(this.validCouponsArray);
    });
  }

  showValidCoupons() {
    return this.storageService.getAllCoupons().then((res: any) => {
      if (res.value) {
        this.allCoupons = JSON.parse(res.value);

        this.storageService.getCouponSetsData().then((couponsets: any) => {
          if (couponsets.value) {
            this.allCouponSets = JSON.parse(couponsets.value);

            // eslint-disable-next-line max-len
            this.validCouponsArray = this.allCoupons.filter(
              (coupon: any) =>
                this.allCouponSets.filter(
                  (couponset: any) =>
                    couponset.couponSetCode ===
                    coupon.couponSetData.couponSetCode
                ).length !== 0
            );

            // eslint-disable-next-line max-len
            this.validCouponsArray = this.validCouponsArray.filter(
              (coupon: any) =>
                coupon.used === false &&
                new Date(coupon.validUntilDate.slice(0, 10)) > new Date()
            );
            // let i = 0;
            // this.validCouponsArray.filter((coupon: any) => {
            //   this.allCouponSets.filter((couponset: any) => {
            //     if(couponset.couponSetCode === coupon.couponSetCode) {
            //       this.validCouponsArray[i].title = couponset.couponSetTitle;
            //     }
            //   });
            //   i += 1;
            // });
          }
          this.validCouponsChanged.next(this.validCouponsArray);
        });
      }
    });
  }

  getValidCouponsArray() {
    return this.validCouponsArray;
  }
}
