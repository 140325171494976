<zxing-scanner
  [torch]="torchEnabled"
  [device]="deviceCurrent"
  (deviceChange)="onDeviceChange($event)"
  (scanSuccess)="onCodeResult($event)"
  [formats]="formatsEnabled"
  [tryHarder]="tryHarder"
  (permissionResponse)="onHasPermission($event)"
  (camerasFound)="onCamerasFound($event)"
  (torchCompatible)="onTorchCompatible($event)"
></zxing-scanner>
<section class="results" *ngIf="qrResultString">
  <div>
    <small>Result</small>
    <strong>{{ qrResultString }}</strong>
  </div>
  <button mat-icon-button (click)="clearResult()">&times;</button>
</section>
<ng-container *ngIf="hasPermission === undefined">
  <h2>Waiting for permissions.</h2>

  <blockquote>
    If your device does not has cameras, no permissions will be asked.
  </blockquote>
</ng-container>

<ng-container *ngIf="hasPermission === false">
  <h2>
    You denied the camera permission, we can't scan anything without it. 😪
  </h2>
</ng-container>

<ng-container *ngIf="hasDevices === undefined">
  <h2>Couldn't check for devices.</h2>

  <blockquote>This may be caused by some security error.</blockquote>
</ng-container>

<ng-container *ngIf="hasDevices === false">
  <h2>No devices were found.</h2>

  <blockquote>
    I believe your device has no media devices attached to.
  </blockquote>
</ng-container>
