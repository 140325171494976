// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpointQC: 'https://kafd.uat.qivos.net/qc-api/v1.0',
  authQC: 'https://kafd.uat.qivos.net/rest-ci-kafd-UAT/rest',
  ssoUrl: 'https://api-kafd-staging.qivos.net',
  //   basic: "Basic YWRtaW46OHdDOGR1Y0FZeHVVU2tGQUpBczU=",
  endpointQivosApi: 'https://api-kafd-staging.qivos.net/',
  endpointIDP: 'https://idp.ci-uat.net',
  config: {
    allowRegistration: false,
    allowCoupons: true,
    reports: {
      allow: true,
      burnedCoupons: {
        allow: true,
      },
    },
    transaction: {
      allow: false,
      enterAmount: {
        allow: false,
      },
      scanReceipt: {
        allow: false,
      },
      searchProduct: {
        allow: true,
      },
    },
    redemption: {
      allow: false,
    },
    coupons: {
      allow: true,
      burnCoupon: false,
    },
  },
  client: 'kafd',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
