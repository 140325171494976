import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from '../../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MemberExistService {

  memberExistsChanged = new Subject<boolean>();
  memberExists: boolean;

  constructor(
    private storageService: StorageService
  ) {
    this.checkMemberExists();
  }

  newMemberSearch() {
    this.memberExists = true;
    this.memberExistsChanged.next(this.memberExists);
  }

  checkMemberExists() {
    this.storageService.getMemberInfo().then((res: any) => {
      if(res.mobileNumber) {
        this.memberExists = true;
      } else {
        this.memberExists = false;
      }
      this.memberExistsChanged.next(this.memberExists);
    });
  }

  getMemberExists() {
    return this.memberExists;
  }
}
