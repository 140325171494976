import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MemberExistService } from "src/app/services/member/member-exist/member-exist.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Input() token: boolean = true;
  @Output() navigationClicked = new EventEmitter<boolean>();
  environment = environment;
  store: any;
  constructor(
    private storageService: StorageService,
    public router: Router,
    private memberExistService: MemberExistService
  ) {}
  ngOnInit() {}

  onLogout() {
    this.storageService.removeToken().then(() => {
      this.memberExistService.checkMemberExists();
      this.router.navigateByUrl("/login");
    });
  }

  navigate() {
    this.navigationClicked.emit(true);
  }
}
