import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(private http: HttpClient) { }

  burnCoupon(body: any, couponCode: string, couponSetCode: string)  {
    const url = `${environment.endpointQC}/coupons/${couponCode}/${couponSetCode}`; 
    return this.http.put(url, body);
  }

  searchCoupons(criteria: any)  {
    const url = `${environment.endpointQC}/coupons/search`; 
    return this.http.post(url, criteria);
  }
}
