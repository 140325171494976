import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";
import { MemberInfo, MemberInfoCodes } from "src/app/models/member-info.model";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  mInfo: MemberInfo = {
    lastName: "",
    firstName: "",
    mobileNumber: "",
    cardNumber: "",
    points: "",
    coupons: "",
    memberCategory: "",
  };

  mInfoCodes: MemberInfoCodes = {
    cQCcode: "",
    mQCcode: "",
  };

  constructor() {}

  async setToken(token: any) {
    const decodedToken = this.getDecodedAccessToken(token);

    await Preferences.set({
      key: "token",
      value: token,
    });

    await Preferences.set({
      key: "token_expires_at",
      value: decodedToken.exp,
    });

    await Preferences.set({
      key: "username",
      value: decodedToken.data.username,
    });
  }

  async getToken() {
    return await Preferences.get({ key: "token" }).then(
      (token: { value: any }) => token
    );
  }

  async getTokenExpiration() {
    return await Preferences.get({ key: "token_expires_at" }).then(
      (token_expires_at: { value: any }) => token_expires_at
    );
  }

  async removeToken() {
    await Preferences.remove({ key: "token" });
    await Preferences.remove({ key: "firstName" });
    await Preferences.remove({ key: "lastName" });
    await Preferences.remove({ key: "mobileNumber" });
    await Preferences.remove({ key: "cardNumber" });
    await Preferences.remove({ key: "memberCategory" });
    await Preferences.remove({ key: "cQCcode" });
    await Preferences.remove({ key: "mQCcode" });
    await Preferences.remove({ key: "points" });
    await Preferences.remove({ key: "coupons" });
    await Preferences.remove({ key: "allCoupons" });
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  async clearPreferences() {
    await Preferences.clear();
  }

  async setStoreInit(
    storeCode: string,
    storeName: string,
    storeAddress: string
  ) {
    await Preferences.set({
      key: "storeCode",
      value: storeCode,
    });
    await Preferences.set({
      key: "storeName",
      value: storeName,
    });
    await Preferences.set({
      key: "storeAddress",
      value: storeAddress,
    });

    await Preferences.set({
      key: "store",
      value: JSON.stringify({
        code: storeCode,
        name: storeName,
        address: storeAddress,
      }),
    });
  }

  async getStoreCode() {
    return await Preferences.get({ key: "storeCode" }).then(
      (storeCode: { value: any }) => storeCode
    );
  }

  async getStoreName() {
    return await Preferences.get({ key: "storeName" }).then(
      (storeName: { value: any }) => storeName
    );
  }

  async getStoreAddress() {
    return await Preferences.get({ key: "storeAddress" }).then(
      (storeAddress: { value: any }) => storeAddress
    );
  }

  async getStore() {
    return await Preferences.get({ key: "store" }).then(
      (store: { value: any }) => store
    );
  }

  async setMemberInfo(
    firstName: string,
    lastName: string,
    mobileNumber: string,
    cardNumber: string,
    points: string,
    coupons: string,
    allCoupons: string,
    memberCategory: string,
    cQCcode: string,
    mQCcode: string,
    updatePersonalInfo: string
  ) {
    await Preferences.set({
      key: "firstName",
      value: firstName,
    });
    await Preferences.set({
      key: "lastName",
      value: lastName,
    });
    await Preferences.set({
      key: "mobileNumber",
      value: mobileNumber,
    });
    await Preferences.set({
      key: "cardNumber",
      value: cardNumber,
    });
    await Preferences.set({
      key: "points",
      value: points,
    });
    await Preferences.set({
      key: "coupons",
      value: coupons,
    });
    await Preferences.set({
      key: "allCoupons",
      value: allCoupons,
    });
    await Preferences.set({
      key: "memberCategory",
      value: memberCategory,
    });
    await Preferences.set({
      key: "cQCcode",
      value: cQCcode,
    });
    await Preferences.set({
      key: "mQCcode",
      value: mQCcode,
    });
    await Preferences.set({
      key: "updatePersonalInfo",
      value: updatePersonalInfo,
    });
  }

  async getMemberInfo() {
    this.mInfo.firstName = await Preferences.get({ key: "firstName" }).then(
      (firstName) => (firstName.value ? firstName.value : "")
    );
    this.mInfo.lastName = await Preferences.get({ key: "lastName" }).then(
      (lastName) => (lastName.value ? lastName.value : "")
    );
    this.mInfo.mobileNumber = await Preferences.get({
      key: "mobileNumber",
    }).then((mobileNumber) => (mobileNumber.value ? mobileNumber.value : ""));
    this.mInfo.cardNumber = await Preferences.get({ key: "cardNumber" }).then(
      (cardNumber) => (cardNumber.value ? cardNumber.value : "")
    );
    this.mInfo.points = await Preferences.get({ key: "points" }).then(
      (points) => (points.value ? points.value : "")
    );
    this.mInfo.memberCategory = await Preferences.get({
      key: "memberCategory",
    }).then((memberCategory) =>
      memberCategory.value ? memberCategory.value : ""
    );
    return this.mInfo;
  }

  async getMemberInfoCodes() {
    this.mInfoCodes.cQCcode = await Preferences.get({ key: "cQCcode" }).then(
      (cQCcode) => (cQCcode.value ? cQCcode.value : "")
    );
    this.mInfoCodes.mQCcode = await Preferences.get({ key: "mQCcode" }).then(
      (mQCcode) => (mQCcode.value ? mQCcode.value : "")
    );
    return this.mInfoCodes;
  }

  async getUpdatePersonalInfo() {
    return await Preferences.get({ key: "updatePersonalInfo" }).then(
      (updatePersonalInfo: { value: any }) => updatePersonalInfo
    );
  }

  async getCoupons() {
    return await Preferences.get({ key: "coupons" }).then(
      (coupons: { value: any }) => coupons
    );
  }

  async getAllCoupons() {
    return await Preferences.get({ key: "allCoupons" }).then(
      (allCoupons: { value: any }) => allCoupons
    );
  }

  async getMemberPoints() {
    return await Preferences.get({ key: "points" }).then(
      (points: { value: any }) => points
    );
  }

  async setMemberPoints(points: string) {
    await Preferences.set({
      key: "points",
      value: points,
    });
  }

  async setCouponSetsData(couponsets: string) {
    await Preferences.set({
      key: "couponsets",
      value: couponsets,
    });
  }

  async getCouponSetsData() {
    return await Preferences.get({ key: "couponsets" }).then(
      (couponsets: { value: any }) => couponsets
    );
  }
}
